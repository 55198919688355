


$('.animatedHeadline').animatedHeadline({
  animationType: "zoom",
  animationDelay: 2500,
  barAnimationDelay: 3800,
  barWaiting: 800,
  lettersDelay: 50,
  typeLettersDelay: 150,
  selectionDuration: 500,
  typeAnimationDelay: 1300,
  revealDuration: 600,
  revealAnimationDelay: 1500
});

$('.container-fluid1').animatedHeadline({
  animationType: "slide",
  animationDelay: 2500,
  barAnimationDelay: 3800,
  barWaiting: 800,
  lettersDelay: 50,
  typeLettersDelay: 150,
  selectionDuration: 500,
  typeAnimationDelay: 1300,
  revealDuration: 600,
  revealAnimationDelay: 1500
});
$('carousel').animatedHeadline({
  animationType: "type",
  animationDelay: 2500,
  barAnimationDelay: 3800,
  barWaiting: 800,
  lettersDelay: 50,
  typeLettersDelay: 150,
  selectionDuration: 500,
  typeAnimationDelay: 1300,
  revealDuration: 600,
  revealAnimationDelay: 1500
});

 

AOS.init({
  duration: 1600,
})



import * as bootstrap from 'bootstrap'